import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { REDUX_STORAGE_KEY } from 'constants/index';

import client from './client';
import settings from './settings';
import api from './api';
import login from './login';
import articles from './articles';
import tips from './tips';
import tags from './tags';

// @ts-expect-error REDUX_STORAGE_KEY is provided to window by SSR
const _window: typeof globalThis & { [REDUX_STORAGE_KEY]: string } = globalThis || {};

const reducer = {
  client,
  settings,
  login,
  articles,
  tips,
  tags,
  [api.reducerPath]: api.reducer,
};

const store = configureStore({
  reducer,
  preloadedState: JSON.parse(
    _window[REDUX_STORAGE_KEY] ? _window[REDUX_STORAGE_KEY].replace(/\*\*\*/g, '\\"').replace(/\#\#\#/g, "'") : '{}',
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(api.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { reducer };
export default store;
