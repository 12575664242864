import { CAN_USE_DOM } from 'constants/index';
import { IS_DEV } from '../constants';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type eventsKeysT = 'event' | 'Category' | 'Action' | 'Label';

type eventsResultT = Record<eventsKeysT, string>;

type eventsParamsT = Omit<eventsResultT, 'event' | 'Category'>;

const hostname: string = 'familytrip.ynet.co.il';

if (CAN_USE_DOM) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    hostname: hostname,
  });
}

export class dataLayerEvents {
  private eventWrapFunction = ({ Action, Label }: eventsParamsT) => {
    const resultObject: eventsResultT = {
      event: 'GA_event',
      Category: 'Tnuva',
      Action,
      Label,
    };

    return !IS_DEV && window.dataLayer.push(resultObject);
    // return window.dataLayer.push(resultObject);
  };

  public onShareClick = (payload: string): void => {
    const params: eventsParamsT = {
      Action: `Share`,
      Label: payload,
    };

    this.eventWrapFunction(params);
  };

  public onHeaderIconsClick = (payload: string): void => {
    const params: eventsParamsT = {
      Action: `clickToWebsite`,
      Label: payload,
    };

    this.eventWrapFunction(params);
  };

  public onWeatherButtonClick = (): void => {
    const params: eventsParamsT = {
      Action: `Weather Update`,
      Label: 'Weather Page',
    };

    this.eventWrapFunction(params);
  };

  public onAccessibilityClick = (): void => {
    const params: eventsParamsT = {
      Action: `Accessibility Button`,
      Label: '',
    };

    this.eventWrapFunction(params);
  };

  public onCategoryClick = (payload: string): void => {
    const CategoriesLabels = {
      '1': 'Trip With Teenagers',
      '2': 'Trip With Kids',
      '3': 'Family time',
      '4': 'Getting Ready for Trip',
      '5': 'Towards Trip',
    };

    const params: eventsParamsT = {
      Action: `Recommendation`,
      Label: CategoriesLabels[payload as keyof typeof CategoriesLabels],
    };

    this.eventWrapFunction(params);
  };

  public onArticleClick = (payload: string, articleTitle: string): void => {
    const CategoriesLabels = {
      '1': 'Trip With Teenagers',
      '2': 'Trip With Kids',
      '3': 'Family time',
      '4': 'Getting Ready for Trip',
      '5': 'Towards Trip',
    };

    const params: eventsParamsT = {
      Action: `Click on Article from Category - ${CategoriesLabels[payload as keyof typeof CategoriesLabels]}`,
      Label: articleTitle,
    };

    this.eventWrapFunction(params);
  };
}
