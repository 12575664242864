import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Categories } from 'models/index';

export interface tagsStateT {
  tags: Array<Categories.Model>;
}

const initialState: tagsStateT = {
  tags: [],
};

export const tags = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setAllTags: (state: tagsStateT, action: PayloadAction<{ tags: Array<Categories.Model> }>) => {
      state.tags = action.payload.tags;
    },
  },
});

export const { setAllTags } = tags.actions;
export default tags.reducer;
