import * as yup from 'yup';

import { Categories } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type UpdateParams = Omit<Categories.Model, 'id' | 'updatedAt'> & {
  id?: Categories.Model['id'];
};
export type UpdateResult = { id: Categories.Model['id'] };
export type UpdateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  id: yup.string(),
  categoryText: yup.string().required(VALIDATION_ERROR.REQUIRED),
  updatedAt: yup.string().required(VALIDATION_ERROR.REQUIRED).default(new Date().toISOString()),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/categories`,
});
