import yup, { AnySchema } from 'yup';

export enum ERROR {
  REQUIRED = 'REQUIRED',
  TOO_SHORT = 'TOO_SHORT',
  TOO_LONG = 'TOO_LONG',
  TOO_WEAK = 'TOO_WEAK',
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_VALUE = 'INVALID_VALUE',
  INVALID_SIZE = 'INVALID_SIZE',
}

export type RawData = Record<string, ERROR[]>;
export type ReadableData = Record<string, string[]>;
export type ValidationResult = { rawErrors: RawData; errors: ReadableData };
export type ValidationShape<P> = { [K in keyof P]: AnySchema };

export const formatValidation = (error: yup.ValidationError): RawData => {
  return error.inner.reduce((acc: Record<string, ERROR[]>, current) => {
    // if (!(Object.values(ERROR) as string[]).includes(current.message)) {
    //   throw new Error(`Failed to format validation data: ${current.message} is not listed in VALIDATION_ERROR enum`);
    // }

    const path = current.path?.replace('[', '.').replace(']', '');
    const code = current.message as ERROR;

    if (path) {
      if (acc[path] === undefined) acc[path] = [];
      acc[path].push(code);
    }

    return acc;
  }, {});
};

export type ValidationTranslation<FormValues = Record<string, unknown>> = Record<
  keyof FormValues,
  Partial<Record<ERROR, string>>
>;

const generalErors = {
  [ERROR.REQUIRED]: 'Required',
  [ERROR.TOO_SHORT]: 'Too short',
  [ERROR.TOO_LONG]: 'Too long',
  [ERROR.TOO_WEAK]: 'Too weak',
  [ERROR.INVALID_FORMAT]: 'Invalid format',
  [ERROR.INVALID_VALUE]: 'Invalid value',
  [ERROR.INVALID_SIZE]: 'Invalid size',
} as const;

const errorPriority = (validationError: ERROR) => {
  const errorPriorities = {
    [ERROR.REQUIRED]: 0,
    [ERROR.TOO_SHORT]: 1,
    [ERROR.TOO_LONG]: 1,
    [ERROR.TOO_WEAK]: 2,
    [ERROR.INVALID_FORMAT]: 3,
    [ERROR.INVALID_VALUE]: 4,
    [ERROR.INVALID_SIZE]: 5,
  } as const;

  return errorPriorities[validationError] || Number.MAX_VALUE;
};

export const translateValidation = (data: RawData, translations?: ValidationTranslation): ReadableData => {
  const result: Record<string, string[]> = {};

  Object.entries(data).forEach(([key, value]) => {
    const sortedErrors = value.sort((a, b) => errorPriority(b) - errorPriority(a));
    result[key] = sortedErrors.map((v: ERROR) => {
      return translations ? translations[key][v] || generalErors[v] : generalErors[v];
    });
  });

  return result;
};

export const validate = async (
  values: Record<string, string | unknown>,
  schema: yup.AnySchema,
  translations?: ValidationTranslation,
): Promise<ValidationResult | null> => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    const rawValidation = formatValidation(error as yup.ValidationError);

    return {
      rawErrors: rawValidation,
      errors: translateValidation(rawValidation, translations),
    };
  }
  return null;
};
