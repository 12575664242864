import { CONFIG, SHARE_CONSTANTS } from 'src/constants';

export const share = (type: string | undefined, isDesktop: boolean | undefined, seoData: Record<string, unknown>) => {
  if (process.env.MODE === 'local') {
    return null;
  }

  const encode = encodeURIComponent;
  const { title, description } = seoData;
  const domain = `${CONFIG.domain}`;

  if (type === SHARE_CONSTANTS.FACEBOOK) {
    const fbURL = 'https://www.facebook.com/sharer.php?u=';

    window.open(`${fbURL}${domain}`, 'sharer', 'width=555,height=600');
  }

  if (type === SHARE_CONSTANTS.WHATSAPP) {
    if (title && description) {
      if (isDesktop) {
        window.open(
          `https://web.whatsapp.com/send?text=${encode(title + '\n' + description + '\n' + domain)}`,
          '_blank',
        );
      } else {
        window.location.href = 'whatsapp://send?text=' + encode(title + '\n' + description + '\n' + domain);
      }
    }
  }
};
