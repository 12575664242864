import * as yup from 'yup';

import { Specialty } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type UpdateParams = Omit<Specialty.Model, 'mainPageLogoUrl' | 'hospitalPageLogoUrl'> & {
  id?: Specialty.Model['id'];
  mainPageLogo?: File;
  hospitalPageLogo?: File;
};
export type UpdateResult = { id: Specialty.Model['id'] };
export type UpdateError = EndpointError;

export const updateSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required(VALIDATION_ERROR.REQUIRED),
  tags: yup.array().of(yup.string()),
  isVisibleOnMain: yup.boolean(),
  mainPageLogo: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) =>
      value && typeof value === 'object'
        ? ['image/png', 'image/jpeg', 'image/jpg'].includes(value.mimetype || value.type)
        : true,
    )
    .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => (value ? value.size <= 1024 * 1024 * 3 : true)),
  hospitalPageLogo: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) =>
      value && typeof value === 'object'
        ? ['image/png', 'image/jpeg', 'image/jpg'].includes(value.mimetype || value.type)
        : true,
    )
    .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => (value ? value.size <= 1024 * 1024 * 3 : true)),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/specialty`,
});
