import * as yup from 'yup';

import { Articles } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type UpdateParams = Omit<Articles.Model, 'id' | 'updatedAt' | 'imageUrl' | 'categories' | 'isVideo' | 'tags'> & {
  id?: Articles.Model['id'];
  image?: File | string;
  categories: string;
  tags: string;
  isVideo: string;
};
export type UpdateResult = { id: Articles.Model['id'] };
export type UpdateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  id: yup.string(),
  title: yup.string(),
  subtitle: yup.string(),
  articleLink: yup.string(),
  categories: yup.string(),
  tags: yup.string(),
  isVideo: yup.string(),
  image: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) => {
      if (!value || typeof value === 'string') {
        return true;
      }

      if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(value?.mimetype || value?.type)) {
        return true;
      }

      return false;
    })
    .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => {
      if (!value || typeof value === 'string') {
        return true;
      }

      if (value && value.size && value.size <= 1024 * 1024 * 3) {
        return true;
      }

      return false;
    }),
  updatedAt: yup.string().required(VALIDATION_ERROR.REQUIRED).default(new Date().toISOString()),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/articles`,
});
