import * as React from 'react';

import { useAppSelector, useResponsive, useTranslation } from 'client/hooks';
import { getArticlesByCategory } from 'client/redux/articles/selectors';
import { dataLayerEvents } from 'client/utils/gtag';

import tipIcon from 'client/assets/icons/tip/tip.png';

import Icon from '../../Icon';

import css from './TourCard.module.scss';

interface Props {
  className?: string;
  categoryId: string;
}

const TourCard: React.FC<Props> = (props) => {
  const { className = '', categoryId } = props;
  const { translate } = useTranslation('buttons');
  const articles = useAppSelector(getArticlesByCategory(categoryId));
  const dataLayerInstance = new dataLayerEvents();

  const [isMobile] = useResponsive('MOBILE');

  const onArticleClick = (articleTitle: string) => (): void => {
    dataLayerInstance.onArticleClick(categoryId, articleTitle);
  };

  return (
    <>
      {articles.map((article) => (
        <React.Fragment key={article.id}>
          <div className={css.tourCardWrapper} key={article.id} onClick={onArticleClick(article.title)}>
            <div className={css.imgWrapper}>
              <img src={article.imageUrl} />
              {article.isVideo && (
                <div className={css.playBtn}>
                  <Icon type="play-button" />
                </div>
              )}
            </div>
            <div className={css.col}>
              <div className={css.colTexts}>
                <div className={css.title}>{article.title}</div>
                <div className={css.description}>{article.subtitle}</div>
              </div>
              <div className={css.categoriesWrapper}>
                {article.tags.map((tag, index: number) => (
                  <div key={`${tag.id} ${index}`} className={css.category}>
                    {tag.categoryText}
                  </div>
                ))}
              </div>
            </div>
            <div className={css.btnWrapper}>
              <a href={article.articleLink} type="button" className={css.tourCardBtn} target="_blank">
                {translate('cardBtn')}
              </a>
            </div>
          </div>
          {!!article.tip && (
            <div className={css.tipWrapper} key={`tip-${article.id}`}>
              {!isMobile && <img src={tipIcon} alt="" />}
              <div className={css.col}>
                <div className={css.title}>
                  {isMobile && <img src={tipIcon} alt="" />}
                  <span>טיפ מתנובה</span>
                </div>
                <div className={css.subtitle}>{article.tip.tipText}</div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default TourCard;
