import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Articles } from 'models/index';

export interface articlesStateT {
  articles: Array<Articles.Model>;
}

const initialState: articlesStateT = {
  articles: [],
};

export const articles = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    setAllArticles: (state: articlesStateT, action: PayloadAction<{ articles: Array<Articles.Model> }>) => {
      state.articles = action.payload.articles;
    },
  },
});

export const { setAllArticles } = articles.actions;
export default articles.reducer;
