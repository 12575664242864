export const CONFIG = JSON.parse(process.env.CONFIG || '{}');
export const REDUX_STORAGE_KEY = '__REDUX_STORAGE__';

export const IS_DEV = process.env.NODE_ENV !== 'production';
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const LOCATIONS = [
  {
    value: '1',
    label: 'תל אביב',
  },
  {
    value: '2',
    label: 'ראשון לציון',
  },
];

export const SHARE_CONSTANTS = {
  WHATSAPP: 'Whatsapp',
  FACEBOOK: 'Facebook',
};

export const SUPPORTED_CV_FILE_FORMATS: string[] = ['application/pdf', 'application/doc', 'application/docx'];
export const FILE_SIZE = 5 * 1024 * 1024;

export type CategoryT = {
  id: string;
  name: string;
};
export const Categories: Array<CategoryT> = [
  {
    id: '1',
    name: 'טיול עם מתבגרים',
  },
  {
    id: '2',
    name: 'טיול עם ילדים',
  },
  {
    id: '4',
    name: 'מתכונים לטיול',
  },
  {
    id: '5',
    name: 'לקראת הטיול',
  },
  {
    id: '3',
    name: 'זמן משפחה',
  },
];
