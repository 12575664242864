import * as yup from 'yup';

import { Tips } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type UpdateParams = Omit<Tips.Model, 'id' | 'updatedAt'> & {
  id?: Tips.Model['id'];
};
export type UpdateResult = { id: Tips.Model['id'] };
export type UpdateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  id: yup.string(),
  tipText: yup.string().required(VALIDATION_ERROR.REQUIRED),
  tipPosition: yup.string().required(VALIDATION_ERROR.REQUIRED),
  updatedAt: yup.string().required(VALIDATION_ERROR.REQUIRED).default(new Date().toISOString()),
  articleCategories: yup.array().min(1, VALIDATION_ERROR.REQUIRED).required(VALIDATION_ERROR.REQUIRED).nullable(),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/tips`,
});
