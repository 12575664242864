import * as React from 'react';
import { useLottie } from 'lottie-react';

import mapAnimation from 'client/assets/jsons/map.json';
import mapIcon from 'client/assets/icons/about/about.png';

import WeatherButton from '../WeatherButton';

import { useResponsive, useTranslation } from 'client/hooks';

import css from './About.module.scss';

interface Props {
  className?: string;
}

const About: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { translate } = useTranslation('about');
  const [isMobile] = useResponsive('MOBILE');

  const mapOptions = {
    animationData: mapAnimation,
    loop: true,
  };

  const { View } = useLottie(mapOptions);

  return (
    <div className={css.aboutWrapper}>
      <div className={css.description}>
        <p>{translate('titleTop')}</p>
        <p>{translate('titleBottom')}</p>
      </div>
      <div className={css.order}>
        {isMobile && <WeatherButton />}
        <div className={css.imgWrapper}>
          <img src={mapIcon} alt="" />
          {View}
        </div>
      </div>
    </div>
  );
};

export default About;
