import * as yup from 'yup';

import { Doctor } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type UpdateParams = Doctor.Model & {
  id?: Doctor.Model['id'];
};
export type UpdateResult = { id: Doctor.Model['id'] };
export type UpdateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required(VALIDATION_ERROR.REQUIRED),
  isPromoted: yup.boolean(),
  specialtyId: yup.string().required(VALIDATION_ERROR.REQUIRED),
  createdAt: yup.string().required(VALIDATION_ERROR.REQUIRED).default(new Date().toISOString()),
  updatedAt: yup.string().required(VALIDATION_ERROR.REQUIRED).default(new Date().toISOString()),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/doctor`,
});
