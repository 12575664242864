import * as React from 'react';
import { useLottie } from 'lottie-react';

import weatherAnimation from 'client/assets/jsons/cloud.json';

import { dataLayerEvents } from 'client/utils/gtag';
import { useTranslation } from 'client/hooks';

import css from './WeatherButton.module.scss';

interface Props {
  className?: string;
}

const WeatherButton: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { translate } = useTranslation('buttons');
  const dataLayerInstance = new dataLayerEvents();

  const weatherOptions = {
    animationData: weatherAnimation,
    loop: true,
  };
  const { View: WeatherAnimation } = useLottie(weatherOptions);

  const onWeatherClick = (): void => {
    dataLayerInstance.onWeatherButtonClick();
  };

  return (
    <div className={css.weatherWrapper}>
      <a href="https://www.ynet.co.il/weather/" onClick={onWeatherClick} target="_blank" className={css.weatherBtn}>
        {translate('weatherBtn')}
      </a>
      {WeatherAnimation}
    </div>
  );
};

export default WeatherButton;
