import { endpoint, EndpointError } from 'endpoints/endpoint';
import { Articles } from 'models/index';

export type GetParams = void;
export type GetResult = { articles: Articles.Model[] };
export type GetError = EndpointError;

export const get = endpoint<GetParams, GetResult, GetError>({
  method: 'GET',
  url: () => '/articles',
});
