import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tips } from 'models/index';

export interface tipsStateT {
  tips: Array<Tips.Model>;
}

const initialState: tipsStateT = {
  tips: [],
};

export const tips = createSlice({
  name: 'tips',
  initialState,
  reducers: {
    setAllTips: (state: tipsStateT, action: PayloadAction<{ tips: Array<Tips.Model> }>) => {
      state.tips = action.payload.tips;
    },
  },
});

export const { setAllTips } = tips.actions;
export default tips.reducer;
