import { Doctor } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';

export type GetParams = { id?: string } | void;
export type GetResult = { doctors: Doctor.Model[] };
export type GetError = EndpointError;

export const get = endpoint<GetParams, GetResult, GetError>({
  method: 'GET',
  url: () => '/doctor',
});
