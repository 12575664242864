import React, { FC, useCallback, useState } from 'react';

import { dataLayerEvents } from 'client/utils/gtag';
import { useTranslation } from 'client/hooks';
import cn from 'classnames';

import { Categories } from 'constants/index';

import hatIcon from 'client/assets/icons/tabs/hat.png';
import hatIconActive from 'client/assets/icons/tabs/hat-active.png';
import documentIcon from 'client/assets/icons/tabs/document.png';
import documentIconActive from 'client/assets/icons/tabs/document-active.png';
import pageIcon from 'client/assets/icons/tabs/page.png';
import pageIconActive from 'client/assets/icons/tabs/page-active.png';
import groupIcon from 'client/assets/icons/tabs/group.png';
import groupIconActive from 'client/assets/icons/tabs/group-active.png';
import bearIcon from 'client/assets/icons/tabs/bear.png';
import bearIconActive from 'client/assets/icons/tabs/bear-active.png';
import TourCard from './TourCard';

import css from './Tabs.module.scss';

interface Props {
  className?: string;
}

const allTabs = (translate: any) => [
  {
    title: translate('withTeenagers'),
    icon: hatIcon,
    iconActive: hatIconActive,
  },
  {
    title: translate('withChildren'),
    icon: bearIcon,
    iconActive: bearIconActive,
  },
  {
    title: translate('recipes'),
    icon: pageIcon,
    iconActive: pageIconActive,
  },
  {
    title: translate('forTheTrip'),
    icon: documentIcon,
    iconActive: documentIconActive,
  },
  {
    title: translate('withFamily'),
    icon: groupIcon,
    iconActive: groupIconActive,
  },
];

const About: FC<Props> = (props) => {
  const { className = '' } = props;
  const { translate } = useTranslation('tabs');
  const dataLayerInstance = new dataLayerEvents();

  const [activeTab, setActiveTab] = useState<string>('3');

  const tabs = allTabs(translate)
    .map((tab, index) => {
      const tabData = {
        ...tab,
        id: Categories[index].id,
      };
      return tabData;
    })
    .reverse();

  const onTabClick = useCallback(
    (id: string) => (): void => {
      setActiveTab(id);
      dataLayerInstance.onCategoryClick(id);
    },
    [],
  );

  return (
    <div className={css.tabsWrapper}>
      <div className={css.title}>{translate('title')}</div>
      <div className={css.scrollableWrapper}>
        <div className={css.tabs}>
          {tabs.map((tab) => (
            <div className={cn(css.tab, activeTab === tab.id && css.active)} key={tab.id} onClick={onTabClick(tab.id)}>
              <img src={activeTab === tab.id ? tab.iconActive : tab.icon} alt="" />
              <div className={css.tabTitle}>{tab.title}</div>
            </div>
          ))}
        </div>
      </div>
      <TourCard categoryId={activeTab} />
    </div>
  );
};

export default About;
