import { Doctor } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';

export type GetPromotedParams = void;
export type GetPromotedResult = { doctors: Doctor.Model[] };
export type GetPromotedError = EndpointError;

export const getPromoted = endpoint<GetPromotedParams, GetPromotedResult, GetPromotedError>({
  method: 'GET',
  url: () => '/doctor/promoted',
});
