import { Specialty } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';

export type GetParams = { id?: string } | void;
export type GetResult = { specialties: Specialty.Model[] };
export type GetError = EndpointError;

export const get = endpoint<GetParams, GetResult, GetError>({
  method: 'GET',
  url: () => '/specialty',
});
