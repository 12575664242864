import * as React from 'react';
import { useLottie } from 'lottie-react';

import Icon from '../Icon';
import WeatherButton from '../WeatherButton';

import tnuIcon from 'client/assets/icons/header/tnu.png';
import ynetIcon from 'client/assets/icons/header/ynet.png';
import vehicleAnimation from 'client/assets/jsons/vehicle.json';
import mountainsAnimation from 'client/assets/jsons/mountains.json';
import arrowsAnimation from 'client/assets/jsons/arrows.json';

import { share } from 'src/utils/share';
import { detectIE } from 'src/utils/detect-ie';
import { CAN_USE_DOM, IS_DEV, SHARE_CONSTANTS } from 'constants/index';
import { dataLayerEvents } from 'client/utils/gtag';

import { useResponsive, useTranslation } from 'client/hooks';

import css from './Header.module.scss';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { translate } = useTranslation('cover');
  const { translate: seoTexts } = useTranslation('seo');

  const [isMobile] = useResponsive('MOBILE');
  const [isDesktop] = useResponsive('DESKTOP');
  const dataLayerInstance = new dataLayerEvents();

  const mountainsOptions = {
    animationData: mountainsAnimation,
    loop: true,
  };
  const vehicleOptions = {
    animationData: vehicleAnimation,
    loop: true,
  };
  const arrowsOptions = {
    animationData: arrowsAnimation,
    loop: true,
  };
  const { View: MountainsAnimation } = useLottie(mountainsOptions);
  const { View: VehicleAnimation } = useLottie(vehicleOptions);
  const { View: ArrowsAnimation } = useLottie(arrowsOptions);

  const accessablityBtn = () => {
    if (!CAN_USE_DOM) {
      return null;
    }

    return document.querySelector('#INDmenu-btn');
  };

  const onButtonClick = () => {
    const btn = accessablityBtn();
    if (btn) {
      const eventClick = new Event('click');
      btn.dispatchEvent(eventClick);
      dataLayerInstance.onAccessibilityClick();
    }
  };

  const onMouseEnter = () => {
    let eventFocus;
    const btn = accessablityBtn();
    if (!CAN_USE_DOM) {
      return;
    }

    if (isDesktop) {
      if (detectIE() > 0) {
        eventFocus = document.createEvent('Event');
        eventFocus.initEvent('focus', false, true);
      } else {
        eventFocus = new Event('focus');
      }

      if (btn) {
        btn.dispatchEvent(eventFocus);
      }
    }
  };

  const onMouseLeave = () => {
    let eventBlur;
    const btn = accessablityBtn();

    if (!CAN_USE_DOM) {
      return;
    }

    if (detectIE() > 0) {
      eventBlur = document.createEvent('Event');
      eventBlur.initEvent('blur', false, true);
    } else {
      eventBlur = new Event('blur');
    }

    if (btn) {
      btn.dispatchEvent(eventBlur);
    }
  };

  const onShare = (e: React.SyntheticEvent<HTMLDivElement>): void => {
    const type: string = `${e.currentTarget.dataset.id}`;
    const seo = {
      title: seoTexts('title'),
      description: seoTexts('shareDescription'),
    };

    share(type, isDesktop, seo);

    dataLayerInstance.onShareClick(type);
  };

  const onYnetLogoClick = (): void => {
    dataLayerInstance.onHeaderIconsClick('ynet');
  };

  const onTnuvaLogoClick = (): void => {
    dataLayerInstance.onHeaderIconsClick('Tnuva');
  };

  return (
    <div className={css.cover}>
      <div className={css.headerWrapper}>
        <div className={css.iconWrapper}>
          <a href="https://www.ynet.co.il/" onClick={onYnetLogoClick} target="_blank">
            <img src={ynetIcon} alt="" />
          </a>
          <a href="https://www.tnuva.co.il/" onClick={onTnuvaLogoClick} target="_blank">
            <img src={tnuIcon} alt="" />
          </a>
        </div>
        <div className={css.socialsWrapper}>
          {!isMobile && <WeatherButton />}
          <div className={css.iconWrapper} onClick={onShare} data-id={SHARE_CONSTANTS.WHATSAPP}>
            <Icon type="whatsapp-icon" />
          </div>
          <div className={css.iconWrapper} onClick={onShare} data-id={SHARE_CONSTANTS.FACEBOOK}>
            <Icon type="fb-icon" />
          </div>
          <div
            className={css.iconWrapper}
            onClick={onButtonClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Icon type="ng-icon" />
          </div>
        </div>
      </div>
      <div className={css.innerWrapper}>
        <div className={css.tnuvaWrapper}>
          <Icon type="tnuca" />
          <div className={css.title}>{translate('title')}</div>
        </div>
        <div className={css.col}>
          <div className={css.mountainsAnimationWrapper}>{MountainsAnimation}</div>
          <div className={css.vehicleAnimationWrapper}>{VehicleAnimation}</div>
        </div>
      </div>
      {!isMobile && <div className={css.arrowsAnimationWrapper}>{ArrowsAnimation}</div>}
    </div>
  );
};

export default Header;
