import { createSelector } from 'reselect';
import _ from 'lodash';

import { RootState } from '..';

export const selectSettings = (state: RootState) => {
  return state.settings;
};

export const selectLang = (state: RootState) => {
  return state.settings.lang;
};

export const selectTexts = createSelector(selectSettings, selectLang, (settings, lang) => {
  return settings.texts[lang];
});

export const selectLoginTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'logIn', {});
});

export const selectCoverTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'cover', {});
});

export const selectAboutTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'about', {});
});

export const selectTabsTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'tabs', {});
});
export const selectButtonsTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'buttons', {});
});
export const selectSeoTexts = createSelector(selectTexts, (texts) => {
  return _.get(texts, 'seo', {});
});
