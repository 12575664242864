import { Categories as Tags, Tips } from 'models/index';
import { articlesStateT } from '.';
import { RootState } from '..';

export const getArticlesByCategory = (categoryId: string) => (state: RootState) => {
  const { articles }: { articles: articlesStateT['articles'] } = state.articles;
  const { tags } = state.tags;
  const { tips } = state.tips;

  let filteredArticles = articles.filter((article) => article.categories.includes(categoryId));

  const resultArticlesData = filteredArticles.map((article) => {
    const articleTags: Array<Tags.Model> = [];
    let articleTip: Tips.Model | undefined;
    article.tags.forEach((tagId) => {
      tags.forEach((tag) => tag.id === tagId && articleTags.push(tag));
    });

    tips.forEach((tip) => {
      if (tip.tipPosition === article.id && tip.articleCategories.includes(categoryId)) {
        articleTip = tip;
      }
    });

    const articleData = {
      ...article,
      tags: articleTags,
      tip: articleTip,
    };

    return articleData;
  });

  return resultArticlesData;
};
